import React from 'react';

interface Props {
  style?: React.CSSProperties;
}

const NotFound = ({ style }: Props) => {
  return (
    <svg
      viewBox="0 0 354 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M5.27273 109.455V89.8182L42.7273 30.9091H60.5455V57.0909H50.3636L30.1818 89.0909V89.8182H85.4546V109.455H5.27273ZM50.5455 124V103.455L51.0909 94.9091V30.9091H74.7273V124H50.5455Z"
        fill="black"
      />
      <path
        d="M176.727 126.545C168.273 126.515 160.97 124.561 154.818 120.682C148.667 116.803 143.924 111.212 140.591 103.909C137.258 96.6061 135.606 87.8485 135.636 77.6364C135.667 67.3939 137.333 58.697 140.636 51.5455C143.97 44.3939 148.697 38.9545 154.818 35.2273C160.97 31.5 168.273 29.6364 176.727 29.6364C185.182 29.6364 192.485 31.5151 198.636 35.2727C204.788 39 209.53 44.4394 212.864 51.5909C216.197 58.7424 217.848 67.4242 217.818 77.6364C217.818 87.9091 216.152 96.697 212.818 104C209.485 111.303 204.742 116.894 198.591 120.773C192.47 124.621 185.182 126.545 176.727 126.545ZM176.727 106C181.091 106 184.667 103.727 187.455 99.1818C190.273 94.6061 191.667 87.4242 191.636 77.6364C191.636 71.2424 191 66.0151 189.727 61.9545C188.455 57.8939 186.697 54.8939 184.455 52.9545C182.212 50.9848 179.636 50 176.727 50C172.364 50 168.803 52.2121 166.045 56.6364C163.288 61.0606 161.879 68.0606 161.818 77.6364C161.788 84.1515 162.409 89.5151 163.682 93.7273C164.955 97.9091 166.712 101 168.955 103C171.227 105 173.818 106 176.727 106Z"
        fill="black"
      />
      <path
        d="M348.727 109.455V89.8182L311.273 30.9091H293.455V57.0909H303.636L323.818 89.0909V89.8182H268.545V109.455H348.727ZM303.455 124V103.455L302.909 94.9091V30.9091H279.273V124H303.455Z"
        fill="black"
      />
    </svg>
  );
};

export default NotFound;
