import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Footer from 'components/common/Footer';
import NotFound from 'components/svg/NotFound';
import HomeIconSvg from 'components/svg/HomeIconSvg';
import { StyledIconSvg } from 'components/common/Header';

const NotFoundPage: FunctionComponent = function () {
  return (
    <NotFoundPageWrapper>
      <Layout>
        <NotFound style={{ width: '50%' }} />
        <NotFoundDescription>
          <Text>
            <strong>OOPS!</strong> YOU'RE LOST
          </Text>
          <StyledLink to="/">
            <Text>
              GO <strong>HOME</strong>
            </Text>
            <HomeIconSvg
              style={{
                strokeWidth: '1.4px',
                transform: 'scaleX(-1)',
                height: '24px',
              }}
            />
          </StyledLink>
        </NotFoundDescription>
      </Layout>
      <Footer />
    </NotFoundPageWrapper>
  );
};

const NotFoundPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
`;

const Layout = styled.div`
  margin-bottom: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  row-gap: 16px;
`;

const NotFoundDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
`;

const StyledLink = styled(StyledIconSvg)`
  display: flex;
  align-items: center;
  column-gap: 4px;

  :hover {
    text-decoration: 1px;
  }
`;

const Text = styled.p`
  font-size: 22px;
  font-weight: 400;
`;
export default NotFoundPage;
